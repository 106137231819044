<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs"
    :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'SCB' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <bank bank-name="SCB"/>
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'KTB' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <bank bank-name="KTB"/>
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'BAY' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <bank bank-name="BAY"/>
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'GSB' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <bank bank-name="GSB"/>
      </div>
    </vs-tab>
  </vs-tabs>


</template>

<script>
import Bank from './Listbank.vue'
export default {
  components: {
    Bank
  },
  data () {
    return {}
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}

</script>

<style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }

</style>
